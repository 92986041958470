import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";

const HetiMenu = ({ data }) => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Ételeink</h1>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h2>Heti ebéd menü</h2>
          <p className="text-justify">
            Hétköznap 11.30-tól, szombaton (tanév közben) 12.00-tól vegetáriánus
            ebédmenüvel, glutén- és laktózmentes opciókkal, vegán fogásokkal
            várunk. Ebédmenünk a napi készlet erejéig érhető el.Csak készpénzt
            tudunk elfogadni!
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {data.allMenu.edges.map(menu => {
            return (
              <GatsbyImage
                image={getImage(menu.node.image)}
                loading="eager"
                alt="Heti menü"
                key={menu.node.id}
                className="img-fluid rounded"
              />
            );
          })}
        </div>
      </div>

      <div className="row my-3">
        <div className="col">
          <h2>Egyéb ételeink</h2>
          <div class="card-group">
            <div className="card">
              <GatsbyImage
                className="card-img-top"
                alt="Harapnivalók"
                image={getImage(data.food1)}
              />
              <div className="card-body">
                <h2 className="card-title">Harapnivalók</h2>
                <p className="card-text">
                  Reggeli kínálat (nyitástól a készlet erejéig)
                </p>
                <ul>
                  <li>Croissant: 200 Ft</li>
                  <li>Croissant vajjal, lekvárral: 500 Ft</li>
                  <li>
                    Egyéb péksüti: 500 Ft (aktuális kínálat szerint
                    barackos-mandulás fonott, fahéjas-kardamomos fonott)
                  </li>
                  <li>Házi granola növényi tejjel: 890 Ft</li>
                  <li>Házi granola vegán csokipudinggal: 990 Ft</li>
                  <li>Smoothie bowl (szezonális!): 890 Ft</li>
                  <li>Szendvics: 690 Ft</li>
                  <li>Panini: 690 Ft</li>
                  <li>Sós pite napi ajánlat szerint: 650 Ft</li>
                  <li>Saláták, mártogatósok, zöldségkrémek szezonálisan.</li>
                </ul>
              </div>
            </div>
            <div className="card">
              <GatsbyImage
                className="card-img-top"
                alt="Édességek"
                image={getImage(data.food2)}
              />
              <div className="card-body">
                <h2 className="card-title">Édességek</h2>
                <p className="card-text">
                  Nyers vegán sütiszeletek aktuális kínálat szerint (6-8 féle
                  mindig akad a pultban).650 Ft-850 Ft / szelet Mind tej-,
                  tojás-, glutén-, laktóz-, cukormentesek. Ízelítő nyers vegán
                  tortáinkból itt.
                </p>
                <ul>
                  <li>Nyers vegán kókuszgolyó: 250 Ft</li>
                  <li>Chia puding, tápióka puding: 690 Ft</li>
                </ul>
                <p className="card-text">
                  Szerdánként általában hagyományos édesség is készül nálunk,
                  császármorzsa, csöröge fánk, mákos guba...
                </p>
                <p className="card-text">
                  Péntekenként pite péntek – azaz a napi sós pite mellett egy
                  édes pite is készül, például feketeriblizlis, meggyes,
                  barackos, szilvás...
                </p>
              </div>
            </div>
            <div className="card">
              <GatsbyImage
                className="card-img-top"
                alt="Kávé, tea, italok"
                image={getImage(data.food3)}
              />
              <div className="card-body">
                <h2 className="card-title">Kávé, tea, italok</h2>
                <p className="card-text">
                  Kávéink francia pörkölésű, Monterosa kávébabból készülnek,
                  Erberling Judit magyar szakember pörköli kézműves pörköléssel,
                  körültekintően odafigyelve arra, milyen alapanyagokat használ.
                </p>
                <ul>
                  <li>presszó: 330 Ft</li>
                  <li>presszó macchiato: 380 Ft</li>
                  <li>ristretto: 330 Ft</li>
                  <li>doppio: 660 Ft</li>
                  <li>hosszú kávé: 350 Ft</li>
                  <li>americano: 350 Ft</li>
                  <li>cappuccino: 420 Ft</li>
                  <li>tejeskávé/latte: 490 Ft</li>
                  <li>melange: 450 Ft</li>
                  <li>jegeskávé: 500 Ft</li>
                  <li>jegeskávé fagyival: 790 Ft</li>
                </ul>
                <p className="card-text">
                  Kávéinkat kérheted koffeinmentes változatban és laktózmentes
                  tejjel, rizstejjel, zabtejjel, mandulatejjel vagy kókusztejjel
                  (ez esetben 330 Ft-990 Ft között változik az egyes kávék ára).
                </p>
                <p className="card-text">
                  Elviteles kávéinkra – amennyiben nem saját bögrédbe, poharadba
                  töltjük – plusz 50 Ft-ot számolunk fel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    allMenu {
      edges {
        node {
          id
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
    food1: file(relativePath: { eq: "foods/reggeli.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    food2: file(relativePath: { eq: "foods/sutik.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    food3: file(relativePath: { eq: "foods/kave.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export const Head = () => <title>Szelence Café | Ételeink</title>;

export default HetiMenu;
